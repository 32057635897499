<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <export-excel
                class="btn excel_btn mr-2"
                :data="list"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                worksheet="Отчет по услугам"
                :name="`Отчет по услугам ${ service.name }.xls`"
              >
                <el-button size="mini" icon="el-icon-document-delete">
                  Excel
                </el-button>
              </export-excel>
              <el-button @click="resetForm()">Закрыть</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="modal-doctoe-info">
      <el-row :gutter="10" class="credit_row">
        <el-col :span="6">
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>{{ service.name }}</div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>{{ reports.total_count }}</div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.total_amount") }}</div>
          <div>{{ reports.total_prices }}</div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.paid") }}</div>
          <div>{{ reports.paid_prices }}</div>
        </el-col>
      </el-row>

      <div class="text-center d-flex sorddata mt-3">
        <el-date-picker
          size="mini"
          v-model="filterForm.start_date"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.end_date"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker>

        <select-region
          size="mini"
          v-model="filterForm.region_id"
          :id="filterForm.region_id"
        ></select-region>
        <div class="px-2">
          <select-city
            size="mini"
            v-model="filterForm.city_id"
            :id="filterForm.city_id"
          ></select-city>
        </div>
      </div>
      <table
        class="table table-hover table-bordered mt-3 table__cerditne__forma"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination_patient"
          @c-change="updateOrderListPagination"
        ></crm-pagination>

        <thead>
          <tr>
            <th>id</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.service") }}</th>
            <th scope="col">{{ $t("message.region") }}</th>
            <th scope="col">{{ $t("message.city") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <th scope="col">{{ $t("message.amount") }}</th>
            <th scope="col">{{ $t("message.paid") }}</th>
            <!-- <th scope="col">Пеня дней</th>
                        <th scope="col">Пеня процентов</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <!-- {{item}} -->
            <td>{{ item.id }}</td>
            <td>
              {{ item ? item.surname + " " + item.first_name : "" }}
            </td>
            <td style="width: 400px">{{ item.service_name }}</td>
            <td>{{ item.region_name }}</td>
            <td>{{ item.city_name }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.total_price_order }}</td>
            <td>{{ item.paid_price }}</td>
            <!-- <td>{{ item.order ? item.order.total_price : '' }}</td>
            <td>{{ item.paid_order.length ? item.paid_order[0].total_price : ''  }}</td> -->
            <!-- <td>0 сум</td>
                        <td>0 %</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import selectRegion from "@/components/inventory-select/select-region";
import selectCity from "@/components/inventory-select/select-city";
export default {
  props: ["selected"],
  components: {
    selectRegion,
    selectCity,
  },
  data() {
    return {
      filterForm: {
        start_date: "",
        end_date: "",
      },
      list: [],
      reports: [],
      service: {},
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      total_amoun: "",
      pagination_patient: {},
    };
  },
  computed: {
    ...mapGetters({
      model: "patientServiceReport/model",
      columns: "patientServiceReport/columns",
      pagination: "patientServiceReport/pagination_patients",
    }),
    total_prices: function () {
      let patient = this.list;
      let prices = 0;
      for (let i = 0; i < patient.length; i++) {
        const element = patient[i];
        element.total_price_order == null
          ? (element.total_price_order = 0)
          : element.total_price_order;
        prices += parseInt(element.total_price_order);
      }
      return prices;
    },
    paid_prices: function () {
      let patient = this.list;
      let prices = 0;
      for (let i = 0; i < patient.length; i++) {
        const element = patient[i];
        element.paid_price == null
          ? (element.paid_price = 0)
          : element.paid_price;
        prices += parseInt(element.paid_price);
      }
      return prices;
    },
  },
  mounted() {
    this.controlExcelData();
  },
  watch: {
    filterForm: {
      handler: function (newVal, oldVal) {
        this.fetchData();
      },
      deep: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      showModel: "patientServiceReport/show",
    }),
    afterOpen() {
      this.loadingData = true;
      this.showModel({
        serviceId: this.selected.id,
        start_date: this.filterForm.start_date,
        region_id: this.filterForm.region_id,
        city_id: this.filterForm.city_id,
        end_date: this.filterForm.end_date,
        page: this.pagination_patient.page,
        per_page: this.pagination_patient.per_page,
        total: this.pagination_patient.total,
      })
        .then((res) => {
          this.list = res.data.patients.data;
          this.pagination_patient = res.data.patients.pagination;
          this.reports = res.data.reports;
          this.service = res.data.service;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    updateOrderListPagination(pagination) {
      this.pagination_patient[pagination.key] = pagination.value;
      this.showModel({
        serviceId: this.selected.id,
        start_date: this.filterForm.start_date,
        region_id: this.filterForm.region_id,
        city_id: this.filterForm.city_id,
        end_date: this.filterForm.end_date,
        page: this.pagination_patient.page,
        per_page: this.pagination_patient.per_page,
        total: this.pagination_patient.total,
      })
        .then((res) => {
          this.list = res.data.patients.data;
          this.reports = res.data.reports;
          this.service = res.data.service;
          this.pagination_patient = res.data.patients.pagination;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    fetchData() {
      this.loadingData = true;
      this.showModel({
        serviceId: this.selected.id,
        start_date: this.filterForm.start_date,
        region_id: this.filterForm.region_id,
        city_id: this.filterForm.city_id,
        end_date: this.filterForm.end_date,
        page: this.pagination_patient.page,
        per_page: this.pagination_patient.per_page,
        total: this.pagination_patient.total,
      })
        .then((res) => {
          this.list = res.data.patients.data;
          this.pagination_patient = res.data.patients.pagination;
          this.reports = res.data.reports;
          this.service = res.data.service;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    controlExcelData() {
      this.excel_fields = {
        id: "id",
        "Ф.И.О": {
          callback: (value) => {
            return value.first_name + " " + value.surname;
          },
        },
        Область: "city_name",
        Район: "region_name",
        "Общая сумма": "total_price_order",
        Оплачено: "paid_price",
        "Дата создания": "created_at",
      };
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawer" });
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 12px;
  }
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>